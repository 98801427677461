<template>
	<div class="section-box drop-out-info" v-show="aftersaleType != aftersaleTypeEnum.upgrade">
		<div class="section-content">
			<div class="sub-title">付款项目</div>
			<div class="dropoutInfo">
				<div class="item">
					<v-text-field
						:value="pageData.payType === 0 ? '学员余额' :pageData.payType === 1? '银行账户':'第三方'"
						label="退费方式"
						hide-details="auto"
						outlined
						:dense="true"
						:height="42"
						disabled
					>
					</v-text-field>
				</div>
				<div class="item" v-if="pageData.payType === 0">
					<v-text-field
						:value="pageData.balanceAccount"
						label="学习账号"
						hide-details="auto"
						outlined
						:dense="true"
						:height="42"
						disabled
					>
					</v-text-field>
				</div>
				<div class="item" v-if="pageData.payType === 0">
					<v-text-field
						:value="pageData.accountName"
						label="学员姓名"
						hide-details="auto"
						outlined
						:dense="true"
						:height="42"
						disabled
					>
					</v-text-field>
				</div>
				<div class="item" v-if="pageData.payType === 1">
					<v-text-field
						:value="pageData.bankAccountName"
						label="学员开户名"
						hide-details="auto"
						outlined
						:dense="true"
						:height="42"
						disabled
					>
					</v-text-field>
				</div>
				<div class="item" v-if="pageData.payType === 1">
					<v-text-field
						:value="pageData.bank"
						label="学员开户行"
						hide-details="auto"
						outlined
						:dense="true"
						:height="42"
						disabled
					>
					</v-text-field>
				</div>
				<div class="item" v-if="pageData.payType === 1">
					<v-text-field
						:value="pageData.bankAccount"
						label="学员户行账号"
						hide-details="auto"
						outlined
						:dense="true"
						:height="42"
						disabled
					>
					</v-text-field>
				</div>
        <div class="item" v-if="pageData.payType === 1" style="display:block; width: 588px;">
					<v-text-field
						:value="pageData.ncAccountName"
						label="付款银行"
						hide-details="auto"
						outlined
						:dense="true"
						:height="42"
						disabled
					>
					</v-text-field>
				</div>
        <div class="item" v-if="pageData.payType !== 0 && pageData.payType !== 1">
					<v-text-field
						:value="pageData.appName + (pageData.appShop? '-' + pageData.appShop:'')"
						label="第三方"
            hide-details="auto"
						outlined
						:dense="true"
						:height="42"
						disabled>
          </v-text-field>
        </div>

			</div>

			<div class="computeBox">
				<div class="title">退费计算</div>
				<div class="right">
					<section v-for="(item, tableIndex) in aftersalePay" :key="tableIndex">
						<div class="goodsAndClass">
							<section class="line1">
								<div class="goodsName" style="width:260px">
									<span>商品名称</span>
									<span v-if="item">{{ item.goodsName }}</span>
									<span v-else>- -</span>
								</div>
								<div class="reciveMoney" style="width:140px">
									<span>已收金额</span>
									<span v-if="item">￥{{ item.receivedMoney | decimal2 }}</span>
									<span v-else>0.00 -</span>
								</div>
								<!-- 成考、网校不显示已上课时金额 -->
								<div class="course-detail" v-if="!(pageData.goodsTemplate === goodsTemplateEnum.ckdp || pageData.goodsTemplate === goodsTemplateEnum.wjdp) && pageData.orderOriginType !== OrderOriginTypeEnum.nc">
									<div style="color: #666">已上课时费用</div>
									<div class="classHis">
											<span>线上课程</span>
											<span v-if="item && item.onlineViewType != 3">
												<span v-show="item.onlineViewType == 0 || item.onlineViewType == 2"
													>{{ item.ineffectiveMonth }}/{{ item.effectiveMonth }} 月, 已上课时费用{{
														item.onlineMonthSpendMoney
													}}元;</span
												>
												<span v-show="item.onlineViewType == 1 || item.onlineViewType == 2"
													>{{ item.signCount }}/{{ item.lessonCount }} 课时, 已上课时费用{{ item.onlineSpendMoney }}元;</span
												>
											</span>
											<span v-else>- -</span>
									</div>
									<div class="classHis">
											<span>线下课程</span>
											<span v-if="item && item.underlineViewType != 3">
												<span v-show="item.underlineViewType == 0 || item.underlineViewType == 2">
													{{ item.ineffectiveMonth }}/{{ item.effectiveMonth }} 月, 已上课时费用{{
														item.underlineMonthSpendMoney
													}}元;</span
												>
												<span v-show="item.underlineViewType == 1 || item.underlineViewType == 2">
												{{ item.signCount }}/{{ item.lessonCount }} 课时, 已上课时费用{{ item.underlineSpendMoney }}元;</span
												>
											</span>
											<span v-else>- -</span>
									</div>
								</div>
								<div class="reciveMoney" style="width:160px" v-if="pageData.goodsTemplate === goodsTemplateEnum.cjzh && pageData.orderOriginType !== OrderOriginTypeEnum.nc">
									<span>核定可退费金额</span>
									<span  style="color:#f54955" v-if="item">￥{{ item.refundMoney | decimal2 }}</span>
									<span style="color:#f54955" v-else>0.00 -</span>
								</div>
							</section>
							<span class="expand" @click="toggleAfterpayTable(tableIndex)" v-if="aftersalePay.length > 1">{{!aftersalePayStatus[tableIndex].expand ? '详情' : '收起' }}<i :class="[!aftersalePayStatus[tableIndex].expand ? 'el-icon-arrow-down' : 'el-icon-arrow-up']"></i></span>
						</div>
						<el-table
							border
							v-show="aftersalePayStatus[tableIndex].expand"
							style="width: 100%; margin: 15px 0 24px 0"
							header-cell-class-name="normal-table-header"
							:cell-style="{fontSize: '13px', color:'#666'}"
							:header-cell-style="{paddingLeft:'15px', fontSize: '13px', color:'#000'}"
							:data="item.inoutProjects"
							show-summary
							:summary-method="onCustomSummaryInoutProjects"
							class="aftersalePayTable"
						>
					
							<template v-for="item of aftersalePayColumns">
								<el-table-column :show-overflow-tooltip="true" :prop="item.prop" :key="item.label" :label="item.label" :min-width="item.width">
									<template v-slot:default="{row}">
										<span v-if="['orderInoutProjectName'].includes(item.prop)">{{ row[item.prop] }}</span>
										<span v-else>{{ row[item.prop] | decimal2 }}</span>
									</template>
								</el-table-column>
							</template>
				
						</el-table>
		
					</section>
				</div>
			</div>
			<!-- 2021/9/10号前贷款暂缓上线 -->
			<!-- <template v-if="saleOrderTable[0] && saleOrderTable[0].installmentType === installmentTypeEnum.yqh"> -->
			<template v-if="false">
				<div class="field_item">
					<i>贷款平台信息</i>
					<i>{{ pageData.loanCompanyName }} - {{ pageData.loanBankName }} - {{ pageData.loanBankAccount }}</i>
				</div>
				<el-row :gutter="20">
					<el-col :span="6">
						<div class="field_item">
							<i>贷款平台退费</i>
							<i class="strong">{{ pageData.loanRefundMoney | decimal2 }}元</i>
						</div>
					</el-col>
					<el-col :span="6">
						<div class="field_item">
							<i>退贷日期</i>
							<i>{{ pageData.loanRefundTime }}</i>
						</div>
					</el-col>
				</el-row>
				<div class="field_item">
					<i>学员已还金额</i>
					<i> 元</i>
				</div>
			</template>
			<div class="dropOutMethd dropMoneyBox">
				<div class="methdName">核定可退总额</div>
				<div class="dropMoney fb24">{{ sumRefundMoney | decimal2 }} 元</div>
			</div>
		</div>
		<div class="section-line"></div>
	</div>
</template>

<script>
import { aftersaleTypeEnum, goodsTemplateEnum, OrderOriginTypeEnum } from '@/views/order/constants'
import { accAdd } from '@/utils/mathMethod'
export default {
	props: {
		aftersaleType: [Number, String],
		pageData: Object,
		aftersalePay: Array,
		aftersalePayStatus: Array,
		sumRefundMoney: [Number, String]
	},
	data(){
		return {
			aftersaleTypeEnum,
			goodsTemplateEnum,
			aftersalePayColumns: [
        { label: "收支项目名称", prop: "orderInoutProjectName", width: '150'},
        { label: "应收金额", prop: "receivableMoney", width: "120" },
        { label: "已收金额", prop: "receivedMoney", width: "120" },
        { label: "扣费金额", prop: "deductionMoney", width: "120" },
        { label: "核定可退金额", prop: "refundableMoney", width: "140" },
      ],
      OrderOriginTypeEnum,
		}
	},
	methods: {
		onCustomSummaryInoutProjects(param) {
      const { columns, data } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "合计"
          return
        }
        let values = data.map((item) => Number(item[column.property]))
        if (column.label === "扣费") {
          let tempValues = []
          data.map((item) => {
            item.deductionsList.map((it) => tempValues.push(isNaN(+it.deductionMoney) ? 0 : +it.deductionMoney))
          })
          values = tempValues
        }
        if (!values.every((value) => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr)
            if (!isNaN(value)) {
              return prev + curr
            } else {
              return prev
            }
          }, 0)
          if (!isNaN(+sums[index])) {
            sums[index] = accAdd(0, +sums[index], 2)
          }
        } else {
          sums[index] = ""
        }
      })
      return sums
    },
		toggleAfterpayTable(tableIndex){
			this.$emit('toggleAfterpayTable', tableIndex)
		}
	}
}
</script>

<style lang="scss" scoped>
.section-box{
    .section-content{
      width: 94%;
      margin: 0 auto;
      padding-top: 18px;
      padding-bottom: 18px;
    }
    .sub-title {
      font-size: 16px;
      font-weight: bold;
      color: #333333;
      padding-bottom: 13px;
    }
    .cell p{
      margin-bottom: 0;
    }
    .section-line{
      height: 12px;
      background: #F5F5FA;
    }
  }
	  .drop-out-info {
    .dropOutMethd {
      color: #000;
      font-size: 16px;
      display: flex;
      margin-top: 8px;
      // margin-bottom: 18px;
      .methdCotent {
        margin-left: 30px;
        span:nth-child(1) {
          margin-right: 50px;
        }
      }
      .bank {
        span:nth-child(2n + 2) {
          margin-right: 10px;
        }
        .info {
          margin-right: 30px;
        }
      }
      .dropMoney {
        margin-left: 20px;
        font-size: 24px;
        color: #f54955;
      }
    }
    .dropMoneyBox {
      display: flex;
      align-items: center;
      .methdName{
        font-size: 14px;
        font-weight: 400;
        line-height: 19px;
        color: #333333;
      }
    }
    .computeBox {
      font-size: 14px;
      .title {
        font-size: 14px !important;
        font-weight: 400;
        color: #333333;
      }
      .right {
        .goodsAndClass {
          background: #F3F6FB;
          line-height: 30px;
          padding: 0 20px;
          border-radius: 3px;
          .goodsName {
            margin-right: 26px;
            width: 300px;
            display: flex;
            flex-direction: column;
            span:nth-child(1) {
              margin-right: 10px;
              color: #666;
            }
            span:nth-child(2) {
              color: #000;
              max-width: 200px;
              overflow: hidden;
              display: inline-block;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
            .goodsSpecName {
              font-size: 10px;
              color: #999;
            }
          }
          .reciveMoney {
            margin-right: 26px;
            display: flex;
            flex-direction: column;
            color: #666;
            span:nth-child(1) {
              margin-right: 10px;
            }
            span:nth-child(2) {
              color: #000;
            }
          }
          .course-detail{
            margin-right: 26px;
          }
          .classHis {
            color: #000;
            span:nth-child(1) {
              margin-right: 14px;
            }
          }
          .studyed {
            margin-right: 26px;
            span:nth-child(1) {
              margin-right: 10px;
            }
            span:nth-child(2) {
              font-weight: bold;
            }
          }
          .canReturn {
            margin-right: 26px;
            span:nth-child(1) {
              margin-right: 10px;
            }
            span:nth-child(2) {
              font-weight: bold;
            }
          }
          .open {
            flex: 1;
            span {
              float: right;
              padding-right: 16px;
              color: #337AFF;
            }
            .closeing {
              cursor: pointer;
              display: block;
              position: relative;
              &::after {
                content: "";
                position: absolute;
                top: 13px;
                width: 10px;
                height: 10px;
                left: 34px;
                border: 1px solid #337AFF;
                border-right: 0;
                border-top: 0;
                transform: rotate(-45deg);
              }
            }
            .opening {
              cursor: pointer;
              display: block;
              position: relative;
              width: 46px;
              &::after {
                content: "";
                position: absolute;
                top: 18px;
                width: 10px;
                height: 10px;
                left: 34px;
                border: 1px solid #337AFF;
                border-left: 0;
                border-bottom: 0;
                transform: rotate(-45deg);
              }
            }
          }
        }
        ::v-deep .el-table__footer-wrapper .deduction-column {
          padding: 0.4rem 0.53333rem;
        }
      }
    }
  }
.dropoutInfo{
	display: flex;
	flex-wrap: wrap;
	.item{
		width: 282px;
		margin-right: 24px;
		margin-bottom: 12px;
	}
}
.goodsAndClass {
  position: relative;
  .line1 {
    display: flex;
    padding: 20px 10px;
  }
  .expand{
    position: absolute;
    right: 24px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    color: #337AFF;
  }
}
</style>